import React from 'react'
import classNames from 'classnames/bind'
import { As, PropsWithAs } from 'reakit-utils/types'

import wrapHs from 'lib/wrapHs'

import * as s from './Text.module.scss'

const cn = classNames.bind(s)

const SCALES = {
  hero: 'hero',
  headline: 'headline',
  body: 'body',
  caption: 'caption',
  subtitle: 'subtitle',
  button: 'button',
  display: 'display',
}

type BaseProps = {
  scale?: keyof typeof SCALES
  children?: React.ReactNode
  className?: string
  style?: React.CSSProperties
}

type BaseType = {
  <Tag extends As>(props: PropsWithAs<BaseProps, Tag>): JSX.Element
}

const Base: BaseType = ({ as: Component = 'p', scale = 'body', children, className, ...props }) => (
  <Component {...props} className={cn(SCALES[scale], className)}>
    {children}
  </Component>
)

const HeroBase: BaseType = ({ as: Component = 'p', scale = 'body', children, className, ...props }) => (
  <Component
    {...props}
    className={cn(SCALES[scale], className)}
    dangerouslySetInnerHTML={{ __html: typeof children === 'string' ? wrapHs(children.toString()) : children }}
  />
)

export const HeroText: BaseType = props => <HeroBase as='h1' scale='hero' {...props} />
export const Headline: BaseType = props => <Base as='h1' scale='headline' {...props} />
export const Subtitle: BaseType = props => <Base scale='subtitle' {...props} />
export const Body: BaseType = props => <Base scale='body' {...props} />
export const CaptionText: BaseType = props => <Base scale='caption' {...props} />
export const ButtonText: BaseType = props => <Base scale='button' {...props} />
export const Display: BaseType = props => <Base scale='display' {...props} />
