import React, { useLayoutEffect, useRef, forwardRef, Ref, ReactNode, useMemo, useState, useEffect } from 'react'
import { DefaultXRControllers, Hands } from '@react-three/xr'
import { useFrame, useThree } from '@react-three/fiber'
import { Box, useHelper, useTexture } from '@react-three/drei'
import { a, useSpring } from '@react-spring/three'
import { useScrollRig } from '@14islands/r3f-scroll-rig'
import { easeQuadInOut } from 'd3-ease'
import {
  WebGLCubeRenderTarget,
  sRGBEncoding,
  Camera,
  DirectionalLight,
  CameraHelper,
  RepeatWrapping,
  BackSide,
} from 'three'
import { useControls } from 'leva'

import { PlutoLogo, MultiAppToolbox, OpenEcosystem, PlutoApp, PlutosphereDark, JoinUs } from 'components/xr/Objects'
import Grid from 'components/xr/Grid'
import TextLabel from 'components/xr/TextLabel'
import RayGrabWithText from 'components/xr/RayGrabWithText'
import TexturePlane from 'components/xr/TexturePlane'
import Stars from 'components/xr/Stars'
import envMapSrc from 'assets/xr/envmap/background/pluto-gradient-env-4096.min.jpg'
import x from 'assets/xr/textures/x.png'
import r from 'assets/xr/textures/r.png'
import diamond from 'assets/xr/textures/diamond.png'
import spheres from 'assets/xr/textures/spheres.png'
import diamondSrc from 'assets/images/repeatable-grid-pattern-2.png'
import alphaMaskSrc from 'assets/images/alpha-mask-radial.png'

// Too heavy
// softShadows()

// @ts-ignore
const ShadowDebug = ({ camera }) => {
  useHelper(camera, CameraHelper, 'cyan')
  return null
}

// useTexture.preload(envMapSrc)
// useGLTF.preload(multiAppGLB)
// useGLTF.preload(openEcoGLB)
// useGLTF.preload(plutoDarkGLB)
// useGLTF.preload(plutoWhiteGLB)

export default forwardRef(function XRScene(
  props: { isAR: boolean; showObjects: boolean; debug: boolean },
  ref: Ref<ReactNode>,
) {
  const { isAR, showObjects = true } = props
  const { requestRender } = useScrollRig()
  useFrame(() => {
    requestRender()
  })

  const box = useRef()
  const floor = useRef()
  const spotLight1 = useRef<DirectionalLight>(null)
  const shadowCamera = useRef<Camera>()

  const xTexture = useTexture(x)
  const rTexture = useTexture(r)
  const diamondTexture = useTexture(diamond)
  const spheresTexture = useTexture(spheres)
  const diamondGrid = useTexture(diamondSrc)

  const { gl, scene } = useThree()
  const [envMap, alphaMaskTexture] = useTexture([envMapSrc, alphaMaskSrc])

  const { fogColor, useFog, fogDensity } = useControls({
    fogColor: '#dfa3ff',
    useFog: true,
    fogDensity: 0.1,
  })

  const {
    showFloor,
    showBackground,
    floorColor,
    floorRoughness,
    floorMetalness,
    floorSize,
    boxRoughness,
    boxMetalness,
    boxColor,
    spotlightIntensity,
    ambientIntensity,
    debugLight,
  } = useControls({
    showFloor: true,
    showBackground: true,
    floorRoughness: { value: 0.5, min: 0, max: 1 },
    floorMetalness: { value: 0.75, min: 0, max: 1 },
    floorColor: '#151515',
    floorSize: { value: 8, min: 1, max: 100 },
    boxRoughness: { value: 0.5, min: 0, max: 1 },
    boxMetalness: { value: 0.6, min: 0, max: 1 },
    boxColor: '#ffffff',
    spotlightIntensity: { value: 0.5, min: 0, max: 3 },
    ambientIntensity: { value: 0, min: 0, max: 1 },
    debugLight: false,
  })
  const [isAnimatingIn, setIsAnimatingIn] = useState(false)

  const fadeOutLoaderSphere = useSpring({
    from: { opacity: 1 },
    opacity: 0,
    delay: 5000,
    config: { easing: easeQuadInOut, duration: 7000 },
  })

  // create Cube env map from Equirectangluar
  useLayoutEffect(() => {
    if (isAR || !showBackground) {
      scene.background = null
      return
    }
    const rt = new WebGLCubeRenderTarget(envMap.image.height)
    rt.fromEquirectangularTexture(gl, envMap)
    rt.texture.encoding = sRGBEncoding // fix rgb
    scene.background = rt.texture
  }, [gl, envMap, scene, showBackground, isAR])

  useLayoutEffect(() => {
    if (spotLight1.current) {
      shadowCamera.current = spotLight1.current.shadow.camera
    }
  }, [spotLight1])

  const diamondGridTable = useMemo(() => {
    const tex = diamondGrid.clone()
    tex.wrapS = tex.wrapT = RepeatWrapping
    tex.repeat.set(20, 20)
    tex.needsUpdate = true
    tex.anisotropy = gl.capabilities.getMaxAnisotropy()
    return tex
  }, [diamondGrid, gl])

  const diamondGridFloor = useMemo(() => {
    const tex = diamondGrid.clone()
    tex.wrapS = tex.wrapT = RepeatWrapping
    tex.repeat.set(20 * 1.25 * floorSize, 20 * 1.25 * floorSize)
    tex.needsUpdate = true
    tex.anisotropy = gl.capabilities.getMaxAnisotropy()
    return tex
  }, [diamondGrid, gl, floorSize])

  useEffect(() => {
    setTimeout(() => {
      setIsAnimatingIn(true)
    }, 500)
  }, [])

  return (
    <>
      {!isAR && (
        // Loader sphere masking out background
        <mesh position-y={2} renderOrder={4}>
          <sphereGeometry args={[2, 8, 8]} />
          <a.meshBasicMaterial color='black' side={BackSide} transparent {...fadeOutLoaderSphere} />
        </mesh>
      )}

      {/* @ts-ignore */}
      {useFog && !isAR && <fogExp2 attach='fog' density={fogDensity} color={fogColor} />}

      {/* This group is moved and placed in AR */}
      <group ref={ref}>
        <mesh position-y={isAR ? 0.0 : 0.51} receiveShadow>
          <boxBufferGeometry attach='geometry' args={[0.8, isAR ? 0.02 : 1, 0.8]} />
          <meshStandardMaterial
            attach='material'
            metalness={boxMetalness}
            roughness={boxRoughness}
            color={boxColor}
            map={diamondGridTable}
          />
          ,
        </mesh>
        {showFloor && !isAR && (
          <>
            {/* Mask out lines with a partially transparent radial blur */}
            <mesh ref={floor} position-y={0.05} rotation={[-Math.PI * 0.5, 0, 0]} renderOrder={2}>
              <circleGeometry attach='geometry' args={[floorSize, 40]} />
              <meshStandardMaterial
                attach='material'
                color={floorColor}
                roughness={floorRoughness}
                metalness={floorMetalness}
                transparent
                alphaMap={alphaMaskTexture}
              />
            </mesh>
            {/* Real floor with diamond texture below */}
            <mesh ref={floor} position-y={0.009} rotation={[-Math.PI * 0.5, 0, 0]}>
              <circleGeometry attach='geometry' args={[floorSize, 40]} />
              <meshBasicMaterial attach='material' color='white' map={diamondGridFloor} />
            </mesh>
          </>
        )}

        {/* spotlight target */}
        <Box ref={box} args={[0.1, 0.1, 0.1]} position-y={isAR ? 0 : 1} visible={false} />

        {showObjects && (
          // TODO animate in all these objects in a nice way ✨
          <>
            <TextLabel text='DEVELOPER PORTAL' position-x={0.16} position-z={0.34} position-y={isAR ? 0.011 : 1.011} />
            <RayGrabWithText
              isAR={isAR}
              title='Visit our Developer Portal'
              body='Develop XR applications that work with the open ecosystem and that can run locally on standards based XR devices or be streamed on PlutoSphere. Discover multi-app and multi-user tools and projects and how to create next-gen XR content for OpenXR and WebXR.'
            >
              <MultiAppToolbox
                position={[0.16, isAR ? 0.073 : 1.073, 0.24]}
                rotation={[0, 0, -Math.PI / 3]}
                willAnimateIn
                isAnimatingIn={isAnimatingIn}
                delay={2500}
              />
            </RayGrabWithText>

            <TextLabel text='OPEN ECOSYSTEM' position-x={0.25} position-z={0.1} position-y={isAR ? 0.011 : 1.011} />
            <RayGrabWithText
              isAR={isAR}
              title='Contributing to open standards'
              body='Pluto bases on industry standards and is actively part of an open ecosystem. Sharing the method and the means in which anyone can make apps that work alongside each other, and with people, no matter where they are.'
            >
              <OpenEcosystem
                position={[0.25, isAR ? 0.067 : 1.067, 0]}
                rotation={[0, -Math.PI * 0.4, 0]}
                willAnimateIn
                isAnimatingIn={isAnimatingIn}
                delay={3500}
              />
            </RayGrabWithText>

            <TextLabel text='PLUTO APP' position-x={-0.12} position-z={0.34} position-y={isAR ? 0.011 : 1.011} />
            <RayGrabWithText
              isAR={isAR}
              title='Connect as if you were in person'
              body='Experience a more personal, intuitive and trustworthy ways to communicate in a remote environment. The whole
            Pluto experience is designed around the principles of shared presence, which makes communication feel more
            real.'
            >
              <PlutoApp
                position={[-0.12, isAR ? 0.03 : 1.03, 0.28]}
                rotation={[Math.PI * 0.02, -Math.PI * 0.25, Math.PI * 0.6]}
                willAnimateIn
                isAnimatingIn={isAnimatingIn}
                delay={3300}
              />
            </RayGrabWithText>

            <TextLabel text='PLUTOSPHERE' position-x={-0.28} position-z={0.18} position-y={isAR ? 0.011 : 1.011} />
            <RayGrabWithText
              isAR={isAR}
              title='Access XR PCs from anywhere'
              body='Pluto is helping you access the power of XR PCs, regardless of what kind of device you have. Stream and play high-end PC VR games over the internet without needing a computer.'
            >
              <PlutosphereDark
                position={[-0.28, isAR ? 0.07 : 1.07, 0.06]}
                willAnimateIn
                isAnimatingIn={isAnimatingIn}
                delay={2200}
              />
            </RayGrabWithText>

            <RayGrabWithText
              isAR={isAR}
              title='Be part of booting up the next reality of computing.'
              cta={{ label: 'EXIT TO VIEW JOBS', link: 'https://plutovr.applytojob.com/apply' }}
            >
              <JoinUs
                position={[0.2, isAR ? 0.035 : 1.035, -0.2]}
                rotation={[0, -Math.PI * 0.6, 0]}
                willAnimateIn
                isAnimatingIn={isAnimatingIn}
                delay={3800}
              />
            </RayGrabWithText>

            <RayGrabWithText
              isAR={isAR}
              title='We help humanity transcend physical location'
              body='With Pluto people can access powerful XR applications, and use them with anyone, no matter of their
              physical location.'
            >
              <PlutoLogo scene={scene} position={[0, isAR ? 0.17 : 1.17, 0]} scale={0.7} />
            </RayGrabWithText>

            <Stars radius={-0.5} depth={4} count={2000} factor={0.2} saturation={1} fade />

            {/* X */}
            <TexturePlane
              args={[0.08, 0.08]}
              position={[-0.3, isAR ? 0.011 : 1.011, -0.26]}
              rotation={[-Math.PI * 0.5, 0, 0]}
              texture={xTexture}
              isAnimatingIn={isAnimatingIn}
              delay={2800}
            />
            {/* R */}
            <TexturePlane
              args={[0.08, 0.08]}
              position={[-0.18, isAR ? 0.011 : 1.011, -0.14]}
              rotation={[-Math.PI * 0.5, 0, 0]}
              texture={rTexture}
              isAnimatingIn={isAnimatingIn}
              delay={3000}
            />
            {/* Diamond */}
            <TexturePlane
              args={[0.078, 0.078]}
              position={[-0.16, isAR ? 0.011 : 1.011, 0.08]}
              rotation={[-Math.PI * 0.5, 0, 0]}
              texture={diamondTexture}
              isAnimatingIn={isAnimatingIn}
              delay={3800}
            />
            {/* Spheres */}
            <TexturePlane
              args={[0.078, 0.157]}
              position={[0.36, isAR ? 0.011 : 1.011, -0.2]}
              rotation={[-Math.PI * 0.5, 0, 0]}
              texture={spheresTexture}
              isAnimatingIn={isAnimatingIn}
              delay={2500}
            />

            <Grid isAR={isAR} />
          </>
        )}

        {/* <axesHelper args={[10]} /> */}
      </group>

      {/* use to produce shadows */}
      <directionalLight
        ref={spotLight1}
        color='white'
        target={box.current}
        castShadow
        position={[0, 2, 0.5]}
        intensity={spotlightIntensity}
        shadow-mapSize-width={512}
        shadow-mapSize-height={512}
        shadow-camera-far={4}
        shadow-camera-left={-1}
        shadow-camera-right={1}
        shadow-camera-top={1}
        shadow-camera-bottom={-1}
        shadow-bias={-0.004}
      />
      <ambientLight intensity={ambientIntensity} />
      {debugLight && shadowCamera.current && <ShadowDebug camera={shadowCamera} />}
      <DefaultXRControllers />
      <Hands />
    </>
  )
})
