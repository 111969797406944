import { SphereGeometry, Mesh, MeshBasicMaterial } from 'three'
import { useEffect } from 'react'

/**
 * Add a fully transparent bounding sphere around the mesh
 * that can catch raycast events more easily
 * @param mesh
 * @param offset
 */
export default function useMeshBoundsHoverTarget(mesh?: THREE.Mesh, offset?: THREE.Vector3) {
  useEffect(() => {
    let boundMeshDebug: THREE.Mesh
    if (mesh) {
      const geometry = mesh.geometry
      geometry.computeBoundingSphere()
      const sphereGeom = new SphereGeometry(geometry.boundingSphere?.radius)
      const debugMat = new MeshBasicMaterial({})
      boundMeshDebug = new Mesh(sphereGeom, debugMat)
      boundMeshDebug.visible = false
      mesh.add(boundMeshDebug)
      offset && boundMeshDebug.position.add(offset)
    }
    return () => {
      boundMeshDebug && boundMeshDebug.removeFromParent()
    }
  }, [mesh, offset])
}
