import { useEffect } from 'react'
import { useWindowSize } from '@react-hook/window-size'

export default function useCalculatedVW() {
  const [width, height] = useWindowSize({ wait: 0 }) // no debounce

  useEffect(() => {
    // get the true viewport width
    const vw = width * 0.01
    // set the value in the --vw var to the root of the document
    document.documentElement.style.setProperty('--vw', `${vw}px`)
  }, [width, height])
}
