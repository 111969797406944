// extracted by mini-css-extract-plugin
export var colorBackground = "#151515";
export var colorForeground = "#fff";
export var colorGradient1Start = "#f969fc";
export var colorGradient1Mid = "#bc01f0";
export var colorGradient1End = "#0c01de";
export var colorCaption = "#e5e4e5";
export var colorGlitchGreen = "#55ff7a";
export var colorGlitchPurple = "#bc01f0";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1000px";
export var breakpointLarge = "1400px";
export var wrapper = "Header-module--wrapper--vHVpk";
export var header = "Header-module--header--GkTAP";
export var caption = "Header-module--caption--SsUlU";
export var last = "Header-module--last--xX4AW";
export var isInView = "Header-module--isInView--YbjJi";