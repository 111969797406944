import create from 'zustand'
import { useEffect } from 'react'
import { useScrollRig } from '@14islands/r3f-scroll-rig'
import { WebGLRenderer } from 'three'

import useCalculatedVH from 'lib/useCalculatedVH'
import useCalculatedVW from 'lib/useCalculatedVW'

type ThemeValue = 'light' | 'dark' | undefined

type Store = {
  headerTheme: ThemeValue
  setHeaderTheme: (prev: ThemeValue) => void
  inXR: boolean
  setInXR: (inXR: boolean) => void
  inVR: boolean
  setInVR: (inVR: boolean) => void
  inAR: boolean
  setInAR: (inAR: boolean) => void
  isLoaded: boolean
  setIsLoaded: (isLoaded: boolean) => void
  loadProgress: number
  setLoadProgress: (prloadProgressogress: number) => void
  isLoaderAnimated: boolean
  setIsLoaderAnimated: (isLoaderAnimated: boolean) => void
  gl?: WebGLRenderer
}

const useUIContext = create<Store>(set => ({
  headerTheme: undefined,
  setHeaderTheme: headerTheme => set({ headerTheme }),
  inXR: false,
  setInXR: inXR => set({ inXR }),
  inVR: false,
  setInVR: inVR => set({ inVR }),
  inAR: false,
  setInAR: inAR => set({ inAR }),
  isLoaded: false,
  setIsLoaded: isLoaded => set({ isLoaded }),
  loadProgress: 0,
  setLoadProgress: loadProgress => set({ loadProgress }),
  isLoaderAnimated: false,
  setIsLoaderAnimated: isLoaderAnimated => set({ isLoaderAnimated }),
  gl: undefined,
}))

export default useUIContext

export const UISideEffects = () => {
  const isLoaded = useUIContext(s => s.isLoaded)
  const isLoaderAnimated = useUIContext(s => s.isLoaderAnimated)
  // @ts-ignore
  const { reflow } = useScrollRig()

  // calculate --vh css var
  useCalculatedVH()
  // calculate --vw css var
  useCalculatedVW()

  useEffect(() => {
    isLoaded && reflow()
    document.documentElement.classList.toggle('is-loaded', isLoaded) // remove overflow: hidden;
    document.documentElement.classList.toggle('is-loader-animated', isLoaderAnimated) // swap loader with main hero text
  }, [isLoaded, isLoaderAnimated, reflow])

  return null
}
