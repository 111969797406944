import React, { useState } from 'react'
import classNames from 'classnames/bind'

import { CaptionText } from 'components/ui/Text'
import Line from 'components/ui/Line'
import GradientBackground from 'components/ui/GradientBackground'
import ViewportEnter from 'components/core/ViewportEnter'

import * as s from './Header.module.scss'

const cn = classNames.bind(s)

const Header = () => {
  const [isInView, setIsInView] = useState(false)

  return (
    <div className={cn('wrapper')}>
      <GradientBackground zIndex={-2} />

      <ViewportEnter onEnter={() => setIsInView(true)}>
        <header className={cn('header', { isInView })}>
          <CaptionText as='h1' className={cn('caption', 'first')}>
            Pluto.App
          </CaptionText>
          <CaptionText as='h2' className={cn('caption', 'last')}>
            Spatial Website
          </CaptionText>
          <Line position={'bottom'} delay={0.1} duration={1.2} />
        </header>
      </ViewportEnter>
    </div>
  )
}

export default Header
