import React from 'react'
import { a, useSpring } from '@react-spring/three'
import { easeExpOut } from 'd3-ease'

type Props = {
  willAnimateIn?: boolean
  isAnimatingIn?: boolean
  delay?: number
}

const config = { duration: 2000, easing: easeExpOut }

const XRPlane = ({ isAnimatingIn = false, delay = 0, ...props }: Props & JSX.IntrinsicElements['mesh']) => {
  const opacitySpring = useSpring({
    'material-opacity': isAnimatingIn ? 1 : 0,
    from: {
      opacity: 0,
    },
    delay,
    config,
  })

  return (
    <a.mesh position={props.position} rotation={props.rotation} {...opacitySpring}>
      <planeBufferGeometry args={props.args} />
      <meshBasicMaterial map={props.texture} transparent={true} />
    </a.mesh>
  )
}

export default XRPlane
