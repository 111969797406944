import React, { useRef, ReactNode, useEffect, useState } from 'react'
import { Group, Matrix4, Vector3, Quaternion } from 'three'
import { useFrame } from '@react-three/fiber'
import { Interactive, useXREvent, XRController } from '@react-three/xr'

import TextBillboard, { BillboardProps } from 'components/xr/TextBillboard'

export default function RayGrabWithText({
  children,
  isAR,
  ...props
}: {
  children: ReactNode
  isAR: boolean
} & BillboardProps) {
  const [showBillboard, setShowBillboard] = useState(false)
  const grabbingController = useRef<XRController>()
  const groupRef = useRef<Group>()

  const groupMatrix = useRef<Matrix4 | undefined>(undefined)
  const groupMatrixInvert = useRef<Matrix4 | undefined>(undefined)
  const previousTransform = useRef<Matrix4 | undefined>(undefined)
  const position = useRef(new Vector3())
  const quaternion = useRef(new Quaternion())
  const targetPosition = useRef(new Vector3())
  const targetQuaternion = useRef(new Quaternion())

  useXREvent('selectend', e => {
    if (grabbingController.current && e.controller.controller === grabbingController.current.controller) {
      grabbingController.current = undefined
      setShowBillboard(false)
    }
  })

  useEffect(() => {
    const group = groupRef.current?.children[0]
    if (groupRef.current && group) {
      group.getWorldPosition(targetPosition.current)
      group.getWorldQuaternion(targetQuaternion.current)
      groupMatrix.current = groupRef.current.matrixWorld.clone()
      groupMatrixInvert.current = groupRef.current.matrixWorld.clone().invert()
    }
  }, [])

  useFrame(() => {
    if (!groupRef.current) return
    const group = groupRef.current?.children[0]

    if (!grabbingController.current && !previousTransform.current) {
      return // not grabbing yet
    }

    // stopped grabbing - return to table
    if (!grabbingController.current && previousTransform.current && groupMatrixInvert.current) {
      group.getWorldPosition(position.current)
      group.getWorldQuaternion(quaternion.current)

      position.current.lerp(targetPosition.current, 0.03)
      quaternion.current.slerp(targetQuaternion.current, 0.03)

      group.position.copy(position.current)
      group.rotation.setFromQuaternion(quaternion.current)

      group.applyMatrix4(groupMatrixInvert.current)
      group.updateWorldMatrix(false, true)

      // stop lerping, when?
      // previousTransform.current = undefined
      return
    }

    // while dragging
    if (grabbingController.current && previousTransform.current && groupMatrix.current && groupMatrixInvert.current) {
      const controller = grabbingController.current.controller
      group.applyMatrix4(groupMatrix.current)
      group.applyMatrix4(previousTransform.current)
      group.applyMatrix4(controller.matrixWorld)
      group.applyMatrix4(groupMatrixInvert.current)

      group.updateWorldMatrix(false, true)
      previousTransform.current = controller.matrixWorld.clone().invert()
    }
  })

  useXREvent('select', _e => {
    // hide when other item is selected
    setShowBillboard(false)
  })

  return (
    <>
      <Interactive
        ref={groupRef}
        onSelectStart={e => {
          grabbingController.current = e.controller
          previousTransform.current = e.controller.controller.matrixWorld.clone().invert()
          setShowBillboard(true)
        }}
        onSelect={() => setShowBillboard(true)}
      >
        {children}
      </Interactive>
      <TextBillboard
        target={groupRef.current?.children[0]}
        visible={showBillboard}
        align={grabbingController.current?.inputSource?.handedness}
        limitY={isAR ? 0.2 : 1.2}
        {...props}
      />
    </>
  )
}
