import React, { useEffect, useRef, useState } from 'react'
import { Text } from '@react-three/drei'
import { useSpring } from '@react-spring/three'
import { easeExpInOut } from 'd3-ease'

import font from 'assets/fonts/Stolzl-Regular.woff'

import { TextLabelType } from './TextLabelTypes'

const TextLabel = ({ text, ...props }: TextLabelType) => {
  const [shouldAnimate, setShouldAnimate] = useState(false)
  const textRef = useRef()

  useEffect(() => {
    setShouldAnimate(true)
  }, [])

  useSpring({
    opacity: shouldAnimate ? 1 : 0,
    delay: 4000,
    config: {
      duration: 2000,
      easing: easeExpInOut,
    },
    onChange: spring => {
      if (textRef.current) {
        // @ts-ignore
        textRef.current.fillOpacity = spring.value.opacity
      }
    },
  })

  return (
    <Text
      ref={textRef}
      font={font}
      fontSize={0.01}
      lineHeight={1}
      color='white'
      anchorX='center'
      anchorY='middle'
      rotation-x={-0.5 * Math.PI}
      fillOpacity={0}
      {...props}
    >
      {text}
    </Text>
  )
}

export default TextLabel
