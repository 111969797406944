import { useEffect } from 'react'
import { useWindowSize } from '@react-hook/window-size'

export default function useCalculatedVH() {
  const [width, height] = useWindowSize({ wait: 0 }) // no debounce

  useEffect(() => {
    // get the true viewport height
    const vh = height * 0.01
    // set the value in the --vh var to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }, [width, height])
}
