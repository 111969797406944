// extracted by mini-css-extract-plugin
export var colorBackground = "#151515";
export var colorForeground = "#fff";
export var colorGradient1Start = "#f969fc";
export var colorGradient1Mid = "#bc01f0";
export var colorGradient1End = "#0c01de";
export var colorCaption = "#e5e4e5";
export var colorGlitchGreen = "#55ff7a";
export var colorGlitchPurple = "#bc01f0";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1000px";
export var breakpointLarge = "1400px";
export var hero = "Text-module--hero--mUQI3";
export var headline = "Text-module--headline--6YFuL";
export var subtitle = "Text-module--subtitle--2fI6V";
export var body = "Text-module--body--cGZYY";
export var caption = "Text-module--caption--fTbob";
export var button = "Text-module--button--dUF+J";
export var display = "Text-module--display--WS+2t";