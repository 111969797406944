/*
Auto-generated by: https://github.com/pmndrs/gltfjsx

NOTE: Updated with important logic.
*/

import { Mesh, MeshStandardMaterial, FrontSide } from 'three'
import React from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader'
import { a, useSpring } from '@react-spring/three'
import { easeExpOut } from 'd3-ease'

import srcDraco from 'assets/xr/glb/optimized/open-ecosystem.draco.glb'
import src from 'assets/xr/glb/open-ecosystem.glb'
import { shouldUseDraco } from 'lib/shouldUseDraco'
const useDraco = shouldUseDraco()

type GLTFResult = GLTF & {
  nodes: {
    purple_cube: Mesh
    blue_cube: Mesh
    pink_cube: Mesh
    white_cube: Mesh
  }
  materials: {
    OPEN_ECOSYSTEM_PURPLE: MeshStandardMaterial
    ['OPEN_ECOSYSTEM_PURPLE.002']: MeshStandardMaterial
    ['OPEN_ECOSYSTEM_PURPLE.003']: MeshStandardMaterial
    ['OPEN_ECOSYSTEM_PURPLE.004']: MeshStandardMaterial
  }
}

type Props = {
  makeFlat?: boolean
  spin?: boolean
  willAnimateIn?: boolean
  isAnimatingIn?: boolean
  delay?: number
}

const config = { duration: 1000, easing: easeExpOut }

export default function Model({
  makeFlat = false,
  willAnimateIn = false,
  isAnimatingIn = false,
  delay = 0,
  ...props
}: Props & JSX.IntrinsicElements['group']) {
  const { nodes, materials } = useGLTF(useDraco ? srcDraco : src, useDraco) as GLTFResult

  const scaleSpring = useSpring({
    scale: willAnimateIn ? (isAnimatingIn ? 1 : 0) : 1,
    from: {
      scale: willAnimateIn ? 0 : 1,
    },
    delay,
    config,
  })
  const matSpring = useSpring({
    envMapIntensity: makeFlat ? 0 : 1,
    config,
  })

  return (
    <group {...props} dispose={null}>
      <a.group {...scaleSpring}>
        <mesh castShadow receiveShadow geometry={nodes.blue_cube.geometry}>
          <a.primitive
            object={materials['OPEN_ECOSYSTEM_PURPLE.002']}
            attach='material'
            {...matSpring}
            side={FrontSide}
          />
        </mesh>
        <mesh castShadow receiveShadow geometry={nodes.white_cube.geometry}>
          <a.primitive
            object={materials['OPEN_ECOSYSTEM_PURPLE.004']}
            attach='material'
            {...matSpring}
            side={FrontSide}
          />
        </mesh>
        <mesh castShadow receiveShadow geometry={nodes.pink_cube.geometry}>
          <a.primitive
            object={materials['OPEN_ECOSYSTEM_PURPLE.003']}
            attach='material'
            {...matSpring}
            side={FrontSide}
          />
        </mesh>
        <mesh castShadow receiveShadow geometry={nodes.purple_cube.geometry}>
          <a.primitive object={materials.OPEN_ECOSYSTEM_PURPLE} attach='material' {...matSpring} side={FrontSide} />
        </mesh>
      </a.group>
    </group>
  )
}

useGLTF.preload(useDraco ? srcDraco : src, useDraco)
