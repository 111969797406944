// extracted by mini-css-extract-plugin
export var colorBackground = "#151515";
export var colorForeground = "#fff";
export var colorGradient1Start = "#f969fc";
export var colorGradient1Mid = "#bc01f0";
export var colorGradient1End = "#0c01de";
export var colorCaption = "#e5e4e5";
export var colorGlitchGreen = "#55ff7a";
export var colorGlitchPurple = "#bc01f0";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1000px";
export var breakpointLarge = "1400px";
export var container = "Line-module--container--qoLLc";
export var top = "Line-module--top--DO6ah";
export var bottom = "Line-module--bottom--gNwrh";
export var left = "Line-module--left--7r78F";
export var right = "Line-module--right--m+rY9";
export var hideOnMobile = "Line-module--hideOnMobile--dVGlV";
export var hideOnDesktop = "Line-module--hideOnDesktop--6jWuq";
export var isInView = "Line-module--isInView--NsUki";