//@ts-nocheck
import React, { useRef } from 'react'
import { useThree } from '@react-three/fiber'
import { a, useSpring } from '@react-spring/three'
import { DoubleSide } from 'three'
import { easeQuadOut } from 'd3-ease'

import { SquarelineProps } from './Props'

const Squareline = ({ points, color, shouldAnimate = false, thickness, delay = 0, duration }: SquarelineProps) => {
  const line = useRef()
  const material = useRef()
  const {
    viewport: { factor },
  } = useThree()

  useSpring({
    dashOffset: shouldAnimate ? 0.85 : 1.65,
    delay: delay * 100,
    config: {
      duration: duration * 1000,
      easing: easeQuadOut,
    },
    onChange: spring => {
      material.current.uniforms.dashOffset.value = spring.value.dashOffset
    },
    onStart: () => {
      material.current.opacity = 1
    },
  })

  return (
    <a.mesh ref={line}>
      <meshLine attach='geometry' points={points} />
      <meshLineMaterial
        attach='material'
        lineWidth={thickness / factor}
        color={color}
        transparent
        ref={material}
        side={DoubleSide}
        dashArray={1.65}
        dashRatio={0.5}
        opacity={0}
        depthWrite={false}
      />
    </a.mesh>
  )
}

export default Squareline
