import * as React from 'react'
import classNames from 'classnames/bind'

import * as s from './Noise.module.scss'
const cn = classNames.bind(s)

type NoiseProps = {
  absolute: boolean
}

const Noise = ({ absolute = false }: NoiseProps) => <div className={cn('noise', { absolute })} />

export default Noise
