export const w = 1
export const h = w * 0.5
const end = 10
const diagonalCoeff = 0.704

export const verticals = [
  // y axis lines left to right
  {
    start: {
      x: -0.42,
      y: 1.5,
      z: -0.5,
    },
    end: {
      x: -0.42,
      y: 1,
      z: -0.5,
    },
  },
  {
    start: {
      x: -0.29,
      y: 1.5,
      z: -0.5,
    },
    end: {
      x: -0.29,
      y: 1.33,
      z: -0.5,
    },
  },
  {
    start: {
      x: -0.165,
      y: 1.5,
      z: -0.5,
    },
    end: {
      x: -0.165,
      y: 1,
      z: -0.5,
    },
  },
  {
    start: {
      x: 0.165,
      y: 1.5,
      z: -0.5,
    },
    end: {
      x: 0.165,
      y: 1,
      z: -0.5,
    },
  },
  {
    start: {
      x: 0.42,
      y: 1.5,
      z: -0.5,
    },
    end: {
      x: 0.42,
      y: 1.25,
      z: -0.5,
    },
  },
  // x axis lines top to bottom
  {
    start: {
      x: -0.5,
      y: 1.58,
      z: -0.5,
    },
    end: {
      x: 0.5,
      y: 1.58,
      z: -0.5,
    },
  },
  {
    start: {
      x: -0.5,
      y: 1.5,
      z: -0.5,
    },
    end: {
      x: 0.5,
      y: 1.5,
      z: -0.5,
    },
  },
  {
    start: {
      x: 0.5,
      y: 1.33,
      z: -0.5,
    },
    end: {
      x: 0.165,
      y: 1.33,
      z: -0.5,
    },
  },
  {
    start: {
      x: -0.5,
      y: 1.33,
      z: -0.5,
    },
    end: {
      x: -0.165,
      y: 1.33,
      z: -0.5,
    },
  },
  {
    start: {
      x: -0.165,
      y: 1.25,
      z: -0.5,
    },
    end: {
      x: 0.5,
      y: 1.25,
      z: -0.5,
    },
  },
  {
    start: {
      x: -0.5,
      y: 1.08,
      z: -0.5,
    },
    end: {
      x: -0.165,
      y: 1.08,
      z: -0.5,
    },
  },
  {
    start: {
      x: 0.5,
      y: 1.08,
      z: -0.5,
    },
    end: {
      x: 0.165,
      y: 1.08,
      z: -0.5,
    },
  },
]

export const horizontals = [
  // z axis lines left to right
  {
    start: {
      x: -0.5,
      y: 1,
      z: -0.5,
    },
    end: {
      x: -0.5,
      y: 1,
      z: 0.5,
    },
  },
  {
    start: {
      x: -0.45,
      y: 1,
      z: -0.4,
    },
    end: {
      x: -0.45,
      y: 1,
      z: 0.25,
    },
  },
  {
    start: {
      x: -0.3,
      y: 1,
      z: -0.4,
    },
    end: {
      x: -0.3,
      y: 1,
      z: -0.1,
    },
  },
  {
    start: {
      x: -0.25,
      y: 1,
      z: -0.1,
    },
    end: {
      x: -0.25,
      y: 1,
      z: 0.5,
    },
  },
  {
    start: {
      x: -0.15,
      y: 1,
      z: -0.5,
    },
    end: {
      x: -0.15,
      y: 1,
      z: 0.15,
    },
  },
  {
    start: {
      x: -0.05,
      y: 1,
      z: 0.15,
    },
    end: {
      x: -0.05,
      y: 1,
      z: 0.5,
    },
  },
  {
    start: {
      x: 0.05,
      y: 1,
      z: 0.15,
    },
    end: {
      x: 0.05,
      y: 1,
      z: 0.5,
    },
  },
  {
    start: {
      x: 0.15,
      y: 1,
      z: -0.5,
    },
    end: {
      x: 0.15,
      y: 1,
      z: 0.15,
    },
  },
  {
    start: {
      x: 0.35,
      y: 1,
      z: 0.15,
    },
    end: {
      x: 0.35,
      y: 1,
      z: 0.5,
    },
  },
  {
    start: {
      x: 0.4,
      y: 1,
      z: -0.35,
    },
    end: {
      x: 0.4,
      y: 1,
      z: -0.15,
    },
  },
  {
    start: {
      x: 0.5,
      y: 1,
      z: -0.5,
    },
    end: {
      x: 0.5,
      y: 1,
      z: 0.5,
    },
  },
  // x axis lines top to bottom
  {
    start: {
      x: -0.5,
      y: 1,
      z: -0.5,
    },
    end: {
      x: 0.5,
      y: 1,
      z: -0.5,
    },
  },
  {
    start: {
      x: -0.5,
      y: 1,
      z: -0.4,
    },
    end: {
      x: -0.15,
      y: 1,
      z: -0.4,
    },
  },
  {
    start: {
      x: 0.15,
      y: 1,
      z: -0.35,
    },
    end: {
      x: 0.5,
      y: 1,
      z: -0.35,
    },
  },
  {
    start: {
      x: -0.5,
      y: 1,
      z: -0.25,
    },
    end: {
      x: -0.15,
      y: 1,
      z: -0.25,
    },
  },
  {
    start: {
      x: -0.15,
      y: 1,
      z: -0.15,
    },
    end: {
      x: 0.5,
      y: 1,
      z: -0.15,
    },
  },
  {
    start: {
      x: -0.5,
      y: 1,
      z: -0.1,
    },
    end: {
      x: -0.15,
      y: 1,
      z: -0.1,
    },
  },
  {
    start: {
      x: -0.25,
      y: 1,
      z: 0.05,
    },
    end: {
      x: -0.15,
      y: 1,
      z: 0.05,
    },
  },
  {
    start: {
      x: -0.25,
      y: 1,
      z: 0.15,
    },
    end: {
      x: 0.5,
      y: 1,
      z: 0.15,
    },
  },
  {
    start: {
      x: -0.5,
      y: 1,
      z: 0.25,
    },
    end: {
      x: 0.05,
      y: 1,
      z: 0.25,
    },
  },
  {
    start: {
      x: -0.25,
      y: 1,
      z: 0.45,
    },
    end: {
      x: 0.35,
      y: 1,
      z: 0.45,
    },
  },
  {
    start: {
      x: -0.5,
      y: 1,
      z: 0.5,
    },
    end: {
      x: 0.5,
      y: 1,
      z: 0.5,
    },
  },
]

export const sides = [
  // y axis lines left to right
  {
    start: {
      x: -0.5,
      y: 1,
      z: 0.5,
    },
    end: {
      x: -0.5,
      y: 0,
      z: 0.5,
    },
  },
  {
    start: {
      x: -0.25,
      y: 1,
      z: 0.5,
    },
    end: {
      x: -0.25,
      y: 0,
      z: 0.5,
    },
  },
  {
    start: {
      x: -0.05,
      y: 1,
      z: 0.5,
    },
    end: {
      x: -0.05,
      y: 0,
      z: 0.5,
    },
  },
  {
    start: {
      x: 0.05,
      y: 1,
      z: 0.5,
    },
    end: {
      x: 0.05,
      y: 0,
      z: 0.5,
    },
  },
  {
    start: {
      x: 0.35,
      y: 1,
      z: 0.5,
    },
    end: {
      x: 0.35,
      y: 0,
      z: 0.5,
    },
  },
  {
    start: {
      x: 0.5,
      y: 1,
      z: 0.5,
    },
    end: {
      x: 0.5,
      y: 0,
      z: 0.5,
    },
  },
  {
    start: {
      x: 0.5,
      y: 1,
      z: 0.15,
    },
    end: {
      x: 0.5,
      y: 0,
      z: 0.15,
    },
  },
  {
    start: {
      x: 0.5,
      y: 1,
      z: -0.15,
    },
    end: {
      x: 0.5,
      y: 0,
      z: -0.15,
    },
  },
  {
    start: {
      x: 0.5,
      y: 1,
      z: -0.35,
    },
    end: {
      x: 0.5,
      y: 0,
      z: -0.35,
    },
  },
  {
    start: {
      x: 0.5,
      y: 1,
      z: -0.5,
    },
    end: {
      x: 0.5,
      y: 0,
      z: -0.5,
    },
  },
  {
    start: {
      x: 0.15,
      y: 1,
      z: -0.5,
    },
    end: {
      x: 0.15,
      y: 0,
      z: -0.5,
    },
  },
  {
    start: {
      x: 0,
      y: 1,
      z: -0.5,
    },
    end: {
      x: 0,
      y: 0,
      z: -0.5,
    },
  },
  {
    start: {
      x: -0.15,
      y: 1,
      z: -0.5,
    },
    end: {
      x: -0.15,
      y: 0,
      z: -0.5,
    },
  },
  {
    start: {
      x: -0.5,
      y: 1,
      z: -0.5,
    },
    end: {
      x: -0.5,
      y: 0,
      z: -0.5,
    },
  },
  {
    start: {
      x: -0.5,
      y: 1,
      z: -0.4,
    },
    end: {
      x: -0.5,
      y: 0,
      z: -0.4,
    },
  },
  {
    start: {
      x: -0.5,
      y: 1,
      z: -0.25,
    },
    end: {
      x: -0.5,
      y: 0,
      z: -0.25,
    },
  },
  {
    start: {
      x: -0.5,
      y: 1,
      z: -0.1,
    },
    end: {
      x: -0.5,
      y: 0,
      z: -0.1,
    },
  },
  {
    start: {
      x: -0.5,
      y: 1,
      z: 0.25,
    },
    end: {
      x: -0.5,
      y: 0,
      z: 0.25,
    },
  },
  {
    start: {
      x: 0.5,
      y: 1,
      z: 0.5,
    },
    end: {
      x: 0.5,
      y: 0,
      z: 0.5,
    },
  },
]

export const floors = [
  // x,z axis lines left to right
  {
    start: {
      x: -0.5,
      y: 0,
      z: 0.5,
    },
    end: {
      x: -end * diagonalCoeff,
      y: 0,
      z: end * diagonalCoeff,
    },
  },
  {
    start: {
      x: -0.5,
      y: 0,
      z: 0.5,
    },
    end: {
      x: -0.5,
      y: 0,
      z: end,
    },
  },
  {
    start: {
      x: -0.25,
      y: 0,
      z: 0.5,
    },
    end: {
      x: -0.25,
      y: 0,
      z: end,
    },
  },
  {
    start: {
      x: -0.05,
      y: 0,
      z: 0.5,
    },
    end: {
      x: -0.05,
      y: 0,
      z: end,
    },
  },
  {
    start: {
      x: 0.05,
      y: 0,
      z: 0.5,
    },
    end: {
      x: 0.05,
      y: 0,
      z: end,
    },
  },
  {
    start: {
      x: 0.35,
      y: 0,
      z: 0.5,
    },
    end: {
      x: 0.35,
      y: 0,
      z: end,
    },
  },
  {
    start: {
      x: 0.5,
      y: 0,
      z: 0.5,
    },
    end: {
      x: 0.5,
      y: 0,
      z: end,
    },
  },
  {
    start: {
      x: 0.5,
      y: 0,
      z: 0.5,
    },
    end: {
      x: end * diagonalCoeff,
      y: 0,
      z: end * diagonalCoeff,
    },
  },
  {
    start: {
      x: 0.5,
      y: 0,
      z: 0.5,
    },
    end: {
      x: end,
      y: 0,
      z: 0.5,
    },
  },
  {
    start: {
      x: 0.5,
      y: 0,
      z: 0.15,
    },
    end: {
      x: end,
      y: 0,
      z: 0.15,
    },
  },
  {
    start: {
      x: 0.5,
      y: 0,
      z: -0.15,
    },
    end: {
      x: end,
      y: 0,
      z: -0.15,
    },
  },
  {
    start: {
      x: 0.5,
      y: 0,
      z: -0.35,
    },
    end: {
      x: end,
      y: 0,
      z: -0.35,
    },
  },
  {
    start: {
      x: 0.5,
      y: 0,
      z: -0.5,
    },
    end: {
      x: end,
      y: 0,
      z: -0.5,
    },
  },
  {
    start: {
      x: 0.5,
      y: 0,
      z: -0.5,
    },
    end: {
      x: end * diagonalCoeff,
      y: 0,
      z: -end * diagonalCoeff,
    },
  },
  {
    start: {
      x: 0.5,
      y: 0,
      z: -0.5,
    },
    end: {
      x: 0.5,
      y: 0,
      z: -end,
    },
  },
  {
    start: {
      x: 0.15,
      y: 0,
      z: -0.5,
    },
    end: {
      x: 0.15,
      y: 0,
      z: -end,
    },
  },
  {
    start: {
      x: 0,
      y: 0,
      z: -0.5,
    },
    end: {
      x: 0,
      y: 0,
      z: -end,
    },
  },
  {
    start: {
      x: -0.15,
      y: 0,
      z: -0.5,
    },
    end: {
      x: -0.15,
      y: 0,
      z: -end,
    },
  },
  {
    start: {
      x: -0.5,
      y: 0,
      z: -0.5,
    },
    end: {
      x: -0.5,
      y: 0,
      z: -end,
    },
  },
  {
    start: {
      x: -0.5,
      y: 0,
      z: -0.5,
    },
    end: {
      x: -end * diagonalCoeff,
      y: 0,
      z: -end * diagonalCoeff,
    },
  },
  {
    start: {
      x: -0.5,
      y: 0,
      z: -0.5,
    },
    end: {
      x: -end,
      y: 0,
      z: -0.5,
    },
  },
  {
    start: {
      x: -0.5,
      y: 0,
      z: -0.4,
    },
    end: {
      x: -end,
      y: 0,
      z: -0.4,
    },
  },
  {
    start: {
      x: -0.5,
      y: 0,
      z: -0.25,
    },
    end: {
      x: -end,
      y: 0,
      z: -0.25,
    },
  },
  {
    start: {
      x: -0.5,
      y: 0,
      z: -0.1,
    },
    end: {
      x: -end,
      y: 0,
      z: -0.1,
    },
  },
  {
    start: {
      x: -0.5,
      y: 0,
      z: 0.25,
    },
    end: {
      x: -end,
      y: 0,
      z: 0.25,
    },
  },
  {
    start: {
      x: -0.5,
      y: 0,
      z: 0.5,
    },
    end: {
      x: -end,
      y: 0,
      z: 0.5,
    },
  },
]

export const circles = [
  {
    size: 1.467,
  },
  {
    size: 3.001,
  },
  {
    size: 6,
  },
]

export const squares = [
  {
    size: 1.3,
  },
  {
    size: 2.651111,
  },
  {
    size: 5.30222,
  },
]
