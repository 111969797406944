//@ts-nocheck
import React, { useEffect, useMemo, useState } from 'react'
import { Vector3 } from 'three'
import { useThree } from '@react-three/fiber'

import Gridline from './Gridline'
import CircleLine from './Circleline'
import Squareline from './Squareline'
import { verticals, horizontals, sides, floors, circles, squares } from './Config'

const setDelays = (arr: []) => [...arr.keys()].sort(() => 0.5 - Math.random())

const duration = 2
const thickness = 0.2

const circleDuration = duration * 1.25
const circleThickness = thickness * 1.65

const floorDuration = duration * 3
const floorThickness = thickness * 1.75

const squareDuration = duration * 3
const squareThickness = thickness * 3.5

const b = {
  x: 0.8,
  y: 1,
  z: 0.8,
} // basis (if box other than 1,1,1)

const mainColor = '#fff'
const floorColor = '#7a7a7a'
const squareColor = '#7a7a7a'
const circleColor = '#555'

const verticalDelays = setDelays(verticals)
const verticalOffset = 0
const horizontalDelays = setDelays(horizontals)
const horizontalOffset = verticals.length - 1
const sideDelays = setDelays(sides)
const sideOffset = horizontalOffset + horizontals.length - 1 + duration
const floorDelays = setDelays(floors)
const floorOffset = sideOffset + sides.length - 1 + duration
const circleDelays = setDelays(circles)
const circleOffset = floorOffset + floors.length * 0.5 - 1 + duration
const squareDelays = setDelays(squares)
const squareOffset = circleOffset

const Grid = (props: { isAR: boolean }) => {
  const [shouldAnimate, setShouldAnimate] = useState(false)
  const [verticalVectors, setVerticalVectors] = useState([])
  const [horizontalVectors, setHorizontalVectors] = useState([])
  const [sideVectors, setSideVectors] = useState([])
  const [floorVectors, setFloorVectors] = useState([])
  const [squareVectors, setSquareVectors] = useState([])
  const {
    viewport: { factor },
  } = useThree()

  const c = (thickness * 0.5) / factor + 0.01 // avoid z fighting
  const cSquare = squareThickness / factor + 0.005 // avoid z fighting (squares)

  useEffect(() => {
    setTimeout(() => {
      setShouldAnimate(true)
    }, 500)
  }, [])

  useMemo(() => {
    setVerticalVectors(
      verticals.map(({ start, end }) => {
        return [
          new Vector3(start.x * b.x, start.y * b.y + c, start.z * b.z + c),
          new Vector3(end.x * b.x, end.y * b.y + c, end.z * b.z + c),
        ]
      }),
    )

    setHorizontalVectors(
      horizontals.map(({ start, end }) => {
        return [
          new Vector3(start.x * b.x, start.y * b.y + c, start.z * b.z),
          new Vector3(end.x * b.x, end.y * b.y + c, end.z * b.z),
        ]
      }),
    )

    setSideVectors(
      sides.map(({ start, end }) => {
        return [
          new Vector3(start.x * b.x, start.y * b.y + c, start.z * b.z),
          new Vector3(end.x * b.x, end.y * b.y + c, end.z * b.z),
        ]
      }),
    )

    setFloorVectors(
      floors.map(({ start, end }) => {
        return [
          new Vector3(start.x * b.x, start.y * b.y + c, start.z * b.z),
          new Vector3(end.x * b.x, end.y * b.y + c, end.z * b.z),
        ]
      }),
    )

    setSquareVectors(
      squares.map(({ size }) => {
        return [
          new Vector3(size * b.x, cSquare, size * b.z),
          new Vector3(size * b.x, cSquare, -size * b.z),
          new Vector3(-size * b.x, cSquare, -size * b.z),
          new Vector3(-size * b.x, cSquare, size * b.z),
          new Vector3(size * b.x, cSquare, size * b.z),
        ]
      }),
    )
  }, [c, cSquare])

  return (
    <group position-y={props.isAR ? -1 : 0}>
      {/* Vertical grid 2d basis*/}
      {verticalVectors.length &&
        verticalVectors.map((_, i) => {
          return (
            <Gridline
              key={`vertical${i}`}
              thickness={thickness}
              points={verticalVectors[i]}
              color={mainColor}
              shouldAnimate={shouldAnimate}
              duration={duration}
              delay={verticalDelays[i] + verticalOffset}
              reverse
              renderOnTop
            />
          )
        })}
      {/* Main horizontal grid */}
      {horizontalVectors.length &&
        horizontalVectors.map((_, i) => {
          return (
            <Gridline
              key={`horizontal${i}`}
              thickness={thickness}
              points={horizontalVectors[i]}
              color={mainColor}
              shouldAnimate={shouldAnimate}
              duration={duration}
              delay={horizontalDelays[i] + horizontalOffset}
            />
          )
        })}
      {/* Side box grid */}
      {!props.isAR &&
        sideVectors.length &&
        sideVectors.map((_, i) => {
          return (
            <Gridline
              key={`side${i}`}
              thickness={thickness}
              points={sideVectors[i]}
              color={mainColor}
              shouldAnimate={shouldAnimate}
              duration={duration}
              delay={sideDelays[i] + sideOffset}
            />
          )
        })}
      {/* Floor grid */}
      {!props.isAR &&
        floorVectors.length &&
        floorVectors.map((_, i) => {
          return (
            <Gridline
              key={`floor${i}`}
              thickness={floorThickness}
              points={floorVectors[i]}
              color={floorColor}
              shouldAnimate={shouldAnimate}
              duration={floorDuration}
              delay={floorDelays[i] + floorOffset}
            />
          )
        })}
      {/* Circles */}
      {!props.isAR &&
        circles.length &&
        circles.map((c, i) => (
          <CircleLine
            key={`circle${i}`}
            size={c.size}
            thickness={circleThickness}
            color={circleColor}
            shouldAnimate={shouldAnimate}
            duration={circleDuration}
            thetaStart={Math.random() * Math.PI * i}
            delay={circleDelays[i] + circleOffset}
          />
        ))}
      {/* Squares */}
      {!props.isAR &&
        squareVectors.length &&
        squares.map((_, i) => (
          <Squareline
            key={`square${i}`}
            thickness={squareThickness}
            points={squareVectors[i]}
            color={squareColor}
            shouldAnimate={shouldAnimate}
            duration={squareDuration}
            delay={squareDelays[i] + squareOffset}
          />
        ))}
    </group>
  )
}

export default Grid
