import React, { useRef, useMemo, useState } from 'react'
import { MathUtils, Vector3, Quaternion, Mesh } from 'three'
import { useThree, useFrame } from '@react-three/fiber'
import { Interactive } from '@react-three/xr'
import lerp from '@14islands/lerp'
import { Text, Box } from '@react-three/drei'
import { a, useSpring } from '@react-spring/three'

import font from 'assets/fonts/Stolzl-Regular.woff'

export type BillboardProps = {
  target?: THREE.Object3D
  visible?: boolean
  align?: 'none' | 'left' | 'right'
  title: string
  body?: string
  cta?: {
    label: string
    link: string
  }
  limitY?: number
}

function getBoundingBoxHeight(mesh: Mesh) {
  return (mesh.geometry.boundingBox?.max.y || 0) - (mesh.geometry.boundingBox?.min.y || 0)
}

function TextBillboard({ target, visible, align = 'none', title, body, cta, limitY = 0.2 }: BillboardProps) {
  const [titleHeight, setTitleHeight] = useState(0)
  const [bodyHeight, setBodyHeight] = useState(0)
  // const [hoveringCTA, setHoverCTA] = useState(false)

  const billboard = useRef<THREE.Group>()
  const billboardContent = useRef<THREE.Mesh>()

  // resources
  const { gl } = useThree()
  const globalPos = useMemo(() => new Vector3(), [])
  const globalQuaternion = useMemo(() => new Quaternion(), [])
  const cameraTarget = useMemo(() => new Vector3(), [])

  // sizes
  const width = cta ? 0.25 : 0.3
  const padding = cta ? width * 0.1 : width * 0.07
  const maxWidth = width - padding * 2
  const titleSize = 0.015
  const bodySize = 0.01
  const margin = 0.01
  const ctaHeight = cta ? titleSize * 2 : 0
  const height = padding + titleHeight + margin + bodyHeight + ctaHeight + padding

  // Drag settings
  const range = 0.3 // how far can it can lag behind before it's clamped
  const lerpAmount = 0.03 // how 'laggy' the position is

  useFrame(({ camera }, delta) => {
    if (!target || !billboard.current || !billboardContent.current || !billboard.current?.parent) return

    // sync global positions
    target.getWorldQuaternion(globalQuaternion)
    target.getWorldPosition(globalPos)

    // convert global pos into local spave
    const localPos = billboard.current.parent.worldToLocal(globalPos)

    // make sure camera target takes teleportation into account
    camera.getWorldPosition(cameraTarget)

    // look at camera
    billboard.current.lookAt(cameraTarget)

    // const offsetTarget = width * 1 * Math.sign(globalPos.x)
    let offsetTarget = 0
    if (align === 'left') {
      offsetTarget = width * -0.1
    } else if (align === 'right') {
      offsetTarget = width * 0.1
    }
    billboardContent.current.position.x = lerp(billboardContent.current.position.x, offsetTarget, lerpAmount, delta)
    billboardContent.current.position.y = height * 1.2
    billboardContent.current.position.z = -height * 0.5
    billboardContent.current.lookAt(cameraTarget)

    // limit Y to not collide with table
    localPos.y = limitY ? Math.max(localPos.y, limitY) : localPos.y

    // no controllers - static position
    if (align === 'none') {
      // XR without controller
      billboard.current.position.y = limitY
    } else {
      // lerp position with limit
      billboard.current.position.x = MathUtils.clamp(
        lerp(billboard.current.position.x, localPos.x, lerpAmount, delta),
        localPos.x - range,
        localPos.x + range,
      )
      billboard.current.position.y = MathUtils.clamp(
        lerp(billboard.current.position.y, localPos.y, lerpAmount, delta),
        localPos.y - range,
        localPos.y + range,
      )
      billboard.current.position.z = MathUtils.clamp(
        lerp(billboard.current.position.z, localPos.z, lerpAmount, delta),
        localPos.z - range,
        localPos.z + range,
      )
    }
  })

  const visibilitySpring = useSpring({
    scale: visible ? 1 : 0,
    'material-opacity': visible ? 0.9 : 0,
    config: {
      tension: visible ? 100 : 500,
      friction: visible ? 14 : 50,
    },
  })

  return (
    <group ref={billboard} visible={visible}>
      <group>
        <a.mesh ref={billboardContent} renderOrder={2} {...visibilitySpring}>
          <planeGeometry args={[width, height]} />
          <meshBasicMaterial color='#151515' transparent />
          <Text
            color='white'
            anchorX='left'
            anchorY='top'
            maxWidth={cta ? maxWidth * 0.8 : maxWidth * 0.6}
            position-z={0.01}
            position-y={height * 0.5 - padding}
            position-x={-maxWidth * 0.5}
            fontSize={titleSize}
            lineHeight={1.5}
            renderOrder={3}
            font={font}
            onSync={(mesh: THREE.Mesh) => setTitleHeight(getBoundingBoxHeight(mesh))}
          >
            {title}
          </Text>
          <Text
            color='white'
            anchorX='left'
            anchorY='bottom'
            maxWidth={maxWidth}
            position-z={0.01}
            position-y={height * -0.5 + padding}
            position-x={-maxWidth * 0.5}
            fontSize={bodySize}
            lineHeight={1.5}
            renderOrder={3}
            font={font}
            onSync={(mesh: THREE.Mesh) => setBodyHeight(getBoundingBoxHeight(mesh))}
          >
            {body}
          </Text>
          {cta && (
            <Interactive
              // onHover={() => setHoverCTA(true)}
              // onBlur={() => setHoverCTA(false)}
              onSelect={async () => {
                window.open(cta.link, '_blank')
                await gl.xr.getSession()?.end()
              }}
            >
              <Box
                args={[maxWidth, ctaHeight, 0.05]}
                material-color='red'
                position-y={height * -0.5 + padding + ctaHeight * 0.7}
                // scale={[1, 1, hoveringCTA ? 1 : 0.1]}
              >
                <meshStandardMaterial roughness={0} metalness={1} color='#999' />
                <Text color='white' position-z={0.025 + 0.001} fontSize={bodySize} renderOrder={3} font={font}>
                  {cta.label}
                </Text>
              </Box>
            </Interactive>
          )}
        </a.mesh>
      </group>
    </group>
  )
}

export default TextBillboard
