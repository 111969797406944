import React, { useState } from 'react'
import classNames from 'classnames/bind'

import ViewportEnter from 'components/core/ViewportEnter'

import { LineType } from './LineTypes'
import * as s from './Line.module.scss'

const cn = classNames.bind(s)

const Line = ({
  position,
  transformOrigin = 'left top',
  duration = 1,
  delay = 0.2,
  hideOnMobile = false,
  hideOnDesktop = false,
  className,
}: LineType) => {
  const [isInView, setIsInView] = useState(false)

  return (
    <ViewportEnter onEnter={() => setIsInView(true)}>
      <span
        className={cn('container', className, position, { hideOnMobile, hideOnDesktop, isInView, custom: !!className })}
        style={
          { transitionDuration: `${duration}s`, transitionDelay: `${delay}s`, transformOrigin } as React.CSSProperties
        }
      />
    </ViewportEnter>
  )
}

export default Line
