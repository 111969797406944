// @ts-nocheck
import React, { Ref, forwardRef, useRef, useState, useEffect, useMemo, useCallback } from 'react'
import { useGLTF, useTexture, Sphere } from '@react-three/drei'
import { Scene, MathUtils, Vector3, Quaternion } from 'three'
import { useFrame, createPortal, invalidate } from '@react-three/fiber'
import { Interactive } from '@react-three/xr'
import { a, useSpring } from '@react-spring/three'
import { easeBackIn, easeQuadInOut } from 'd3-ease'
import mergeRefs from 'react-merge-refs'
import lerp from '@14islands/lerp'

// Assets
import src from 'assets/plutosphere/01_model/Plutoshpere_shell_noMaterial_closed.glb'
import gradientPrimarySrc from 'assets/plutosphere/03_gradients/01_gradient-primary-default.png'
import normalMapSrc from 'assets/plutosphere/02_texture/1K/MetalGraphitePitted001_NRM_1K_METALNESS.jpg'

import { MagicalMaterial } from './MagicalMaterial'

const AnimatedMagicalMaterial = a(MagicalMaterial)

const STATE_IDLE = 0
const STATE_HOVER = 1
const STATE_SELECT = 2
const STATE_CLOSED = 3

const EASE_BALL_DEFAULT = { tension: 133, friction: 20 }
const EASE_BALL_UNHOVER = { tension: 100, friction: 20 }
const EASE_BALL_UNSELECT = { tension: 10, friction: 10 }

const EASE_BOTTOM_PART_CLOSING = { easing: easeBackIn.overshoot(2), duration: 800 }
const EASE_BALL_CLOSING_SCALE = { easing: easeQuadInOut, duration: 800 }
const EASE_BALL_OPENING = { tension: 133, friction: 10 }

const EASE_SHELL_OPEN_EASING = { easing: easeBackIn.overshoot(5), duration: 1000 }
const EASE_SHELL_CLOSED_EASING = { tension: 100, friction: 10 }

type PlutoLogoProps = {
  scene: Scene
  visible?: boolean
  draggable?: boolean
  makeFlat?: boolean
  makeSelected?: boolean
  makeClosed?: boolean
  position?: number[]
  scale?: number[] | number
}

/**
 * Interactive Plutosphere v1.0
 *
 * All default values reflect the materials outlined in `Pluto_brand-guidelines-v1-0` and should not be changed.
 *
 * The debug controls have been intentionally left in place to allow for easy inspection of how the materials are configured.
 *
 * @param {bool} darkTheme Theme selection
 * @param {object} ref React ref from forwardRef
 */
function PlutoLogo(
  {
    scene,
    visible = true,
    draggable = true,
    makeFlat = false,
    makeSelected = false,
    makeClosed = false,
    ...props
  }: PlutoLogoProps,
  ref: Ref<HTMLDivElement>,
) {
  const material = useRef()
  const shellPos = useRef()
  const ballPos = useRef()
  const ballScale = useRef()
  const ballInner = useRef()

  // resources
  const delta = useMemo(() => new Vector3(), [])
  const globalPos = useMemo(() => new Vector3(), [])
  const globalQuaternion = useMemo(() => new Quaternion(), [])
  const { nodes } = useGLTF(src, false)
  const [shellMaterial, setShellMaterial] = useState()
  const [normalMap] = useTexture([normalMapSrc])
  const gradientPrimary = useTexture(gradientPrimarySrc)

  const [fullyVisible, setFullyVisible] = useState(visible)

  // MATERIAL PROPERTIES
  const shellMaterialProps = {
    color: useState('#ffffff'),
    roughness: useState(0.4),
    metalness: useState(0.4),
    envMapIntensity: useState(0.4),
    clearcoat: useState(0),
    clearcoatRoughness: useState(0),
    colorMap: useState(false),
    roughnessMap: useState(false),
    normalMap: useState(true),
    normalScale: useState(1.4),
  }
  const presenceMaterialProps = {
    roughness: useState(0.14),
    metalness: useState(0.14),
    envMapIntensity: useState(0.6),
    clearcoat: useState(0),
    clearcoatRoughness: useState(0),
    transmission: useState(0.0),
    ior: useState(1.0),
    reflectivity: useState(0.5),
    opacity: useState(1),
  }

  // DEBUG RENDER OPTIONS
  const shellVisible = true
  const shellWireframe = false

  // Interaction state
  const [hovering, setHovering] = useState(false)
  const [hoveringBottom, setHoverBottom] = useState(false)
  const [selected, setSelected] = useState(false)
  const [closed, setClosed] = useState(false)
  const [animationState, setAnimationState] = useState(STATE_IDLE)

  // NOISE STATE / PROPS
  const [idleDistort] = useState(0.3)
  const [idleFrequency] = useState(3.0)
  const [idleRadius] = useState(0.88)
  const [idleSpeed] = useState(2)

  const [hoverDistort] = useState(0.4)
  const [hoverFrequency] = useState(1.5)
  const [hoverRadius] = useState(0.75)
  const [hoverSpeed] = useState(5)

  const [selectDistort] = useState(0.4)
  const [selectFrequency] = useState(1)
  const [selectRadius] = useState(0.84)
  const [selectSpeed] = useState(9)

  // Close state
  const [closedDistort] = useState(0.2)
  const [closedFrequency] = useState(0.6)
  const [closedRadius] = useState(0.97)
  const [closedSpeed] = useState(4)

  // Animation State
  const distort = [idleDistort, hoverDistort, selectDistort, closedDistort]
  const frequency = [idleFrequency, hoverFrequency, selectFrequency, closedFrequency]
  const radius = [idleRadius, hoverRadius, selectRadius, closedRadius]
  const speed = [idleSpeed, hoverSpeed, selectSpeed, closedSpeed]

  // BALL METARIAL SPRING
  const [ballEasing, setBallEasing] = useState(EASE_BALL_DEFAULT)
  const ballMaterialSpring = useSpring({
    distort: distort[animationState],
    frequency: frequency[animationState],
    radius: radius[animationState],
    speed: speed[animationState],
    envMapIntensity: makeFlat || !fullyVisible ? 0 : 1,
    config: ballEasing,
    onChange: () => invalidate(),
  })

  const shellMaterialSpring = useSpring({
    envMapIntensity: makeFlat || !fullyVisible ? 0 : 1,
    config: ballEasing,
    onChange: () => invalidate(),
  })

  const opacitySpring = useSpring({
    opacity: visible ? 1 : 0,
    config: { easing: easeQuadInOut, duration: 1000 },
    onChange: () => invalidate(),
    onRest: () => setFullyVisible(true),
  })

  // OPEN/CLOSE SPRING
  const bottomPartSpring = useSpring({
    position: closed ? [0, 0, 0] : [0, -0.1, 0],
    delay: closed ? 0 : 1000,
    config: closed ? EASE_BOTTOM_PART_CLOSING : EASE_BALL_OPENING,
  })
  const ballSpringScale = useSpring({
    scale: closed ? [1.95, 1.95, 1.95] : [1, 1, 1],
    delay: closed ? 250 : 1000,
    config: closed ? EASE_BALL_CLOSING_SCALE : EASE_BALL_OPENING,
  })
  const ballSpringPos = useSpring({
    position: closed ? [0, 0, 0] : [-0.05, -0.05, 0.0],
    delay: closed ? 0 : 1000,
    config: closed ? EASE_BOTTOM_PART_CLOSING : EASE_BALL_OPENING,
  })
  const part1Spring = useSpring({
    position: closed ? [-0.0022, 0.0022, 0] : [0, 0, 0],
    delay: closed ? 800 : 0,
    config: closed ? EASE_SHELL_CLOSED_EASING : EASE_SHELL_OPEN_EASING,
  })
  const part2Spring = useSpring({
    position: closed ? [0.0022, 0.0022, 0] : [0, 0, 0],
    delay: closed ? 800 : 0,
    config: closed ? EASE_SHELL_CLOSED_EASING : EASE_SHELL_OPEN_EASING,
  })
  const part3Spring = useSpring({
    position: closed ? [0.0022, -0.0022, 0] : [0, 0, 0],
    delay: closed ? 800 : 0,
    config: closed ? EASE_SHELL_CLOSED_EASING : EASE_SHELL_OPEN_EASING,
  })
  const part4Spring = useSpring({
    position: closed ? [-0.0022, -0.0022, 0] : hoveringBottom ? [0, -0.01, 0] : [0, 0, 0],
    delay: closed ? 800 : 0,
    config: closed || hoveringBottom ? EASE_SHELL_CLOSED_EASING : EASE_SHELL_OPEN_EASING,
  })

  /////////////////////////////////////////////////////////////////////////////
  // EVENT HANDLERS
  /////////////////////////////////////////////////////////////////////////////

  const onHover = useCallback(() => {
    if (closed) return
    setBallEasing(EASE_BALL_DEFAULT)
    setHovering(true)
  }, [closed])

  const onBlur = useCallback(() => {
    if (closed) return
    setBallEasing(EASE_BALL_UNHOVER)
    setHovering(false)
  }, [closed])

  const onSelect = useCallback(
    active => {
      if (closed) return
      setBallEasing(active ? EASE_BALL_DEFAULT : EASE_BALL_UNSELECT)
      setSelected(active)
    },
    [closed],
  )

  const onClose = useCallback(close => {
    setHoverBottom(false)
    if (!close) return // don't allow closing if already closed
    setBallEasing(EASE_BOTTOM_PART_CLOSING)
    setClosed(true)

    // Auto open after 3s
    setTimeout(() => {
      setBallEasing(EASE_BALL_DEFAULT)
      setClosed(false)
    }, 3000)
  }, [])

  const toggleClose = useCallback(closed => {
    setBallEasing(closed ? EASE_BOTTOM_PART_CLOSING : EASE_BALL_DEFAULT)
    setClosed(closed)
  }, [])

  /////////////////////////////////////////////////////////////////////////////
  // UTILS
  /////////////////////////////////////////////////////////////////////////////

  const shellMatProp = prop => {
    return shellMaterialProps[prop][0]
  }
  const presenceMatProp = prop => {
    return presenceMaterialProps[prop][0]
  }
  // react-three-gui sometimes doesnt return a hex for some reason
  const getShellColor = () => {
    const c = shellMatProp('color')
    if (typeof c === 'object') {
      return `rgba(${c.r},${c.g},${c.b},${c.a || 1})`
    }
    return c
  }

  /////////////////////////////////////////////////////////////////////////////
  // EFFECTS
  /////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    makeSelected && onSelect(true)
    return () => onSelect(false)
  }, [makeSelected, onSelect])

  useEffect(() => {
    toggleClose(makeClosed)
  }, [makeClosed, toggleClose])

  // Trigger animations based on interaction
  useEffect(() => {
    if (closed) {
      setAnimationState(STATE_CLOSED)
    } else if (selected) {
      setAnimationState(STATE_SELECT)
    } else if (hovering) {
      setAnimationState(STATE_HOVER)
    } else {
      setAnimationState(STATE_IDLE)
    }
  }, [hovering, selected, closed])

  /////////////////////////////////////////////////////////////////////////////
  // ANIMATION FRAME
  /////////////////////////////////////////////////////////////////////////////

  useFrame((_, clockDelta) => {
    if (!shellPos.current || !ballPos.current) return

    // sync global positions
    shellPos.current.getWorldQuaternion(globalQuaternion)
    shellPos.current.getWorldPosition(globalPos)
    ballPos.current.quaternion.copy(globalQuaternion)

    // Calculate delta vector between ball&shell and rotate to match the global shell rotation
    delta.copy(globalPos)
    delta.sub(ballPos.current.position)
    delta.applyQuaternion(globalQuaternion.conjugate())

    // Drag settings
    const range = closed ? 0 : 0.007 // how far can it can lag behind before it's clamped
    const lerpAmount = animationState === STATE_CLOSED ? 1 : 0.5 // how 'laggy' the position is

    if (draggable) {
      // Create range -1 to 1 of delta
      const rangeDelta = {
        x: MathUtils.clamp(delta.x / range, -1, 1),
        y: MathUtils.clamp(delta.y / range, -1, 1),
        z: MathUtils.clamp(delta.z / range, -1, 1),
      }

      // adjust scale based on delta
      if (animationState === STATE_CLOSED) {
        ballScale.current.scale.setScalar(1)
      } else {
        ballScale.current.scale.y = 1 - Math.abs(rangeDelta.y) * 0.2 // squishiness in Y-axis
        ballScale.current.scale.x = 1 + rangeDelta.x * 0.14 // squishiness in X-axis
        ballScale.current.scale.z = 1 + Math.abs(rangeDelta.z) * 0.14 // squishiness in Z-axis
      }

      // lerp position with limit

      ballPos.current.position.x = MathUtils.clamp(
        lerp(ballPos.current.position.x, globalPos.x, lerpAmount, clockDelta),
        globalPos.x - range,
        globalPos.x + range,
      )
      ballPos.current.position.y = MathUtils.clamp(
        lerp(ballPos.current.position.y, globalPos.y, lerpAmount, clockDelta),
        globalPos.y - range,
        globalPos.y + range,
      )
      ballPos.current.position.z = MathUtils.clamp(
        lerp(ballPos.current.position.z, globalPos.z, lerpAmount, clockDelta),
        globalPos.z - range,
        globalPos.z + range,
      )

      if (material.current) {
        // increment speed based on delta
        material.current.speed = Math.max(speed[animationState], delta.length() * 2000)

        // speed
        ballInner.current.rotation.x += material.current.speed * 0.002
        ballInner.current.rotation.z += material.current.speed * 0.002
      }
    }
  })

  const renderPresence = props => (
    <group dispose={null} {...props} ref={ballPos}>
      {/* Animated ball position based on Animation state */}
      <a.mesh {...ballSpringPos}>
        <a.mesh {...ballSpringScale}>
          {/* dynamic scaling on this one */}
          <mesh ref={ballScale}>
            {/* dynamic rotation based on speed on this one */}
            <mesh ref={ballInner} receiveShadow castShadow>
              <sphereBufferGeometry args={[0.05, 32, 32]} />
              <AnimatedMagicalMaterial
                ref={material}
                map={gradientPrimary}
                transparent
                opacity={presenceMatProp('opacity')}
                roughness={presenceMatProp('roughness')}
                metalness={presenceMatProp('metalness')}
                transmission={presenceMatProp('transmission')}
                ior={presenceMatProp('ior')}
                reflectivity={presenceMatProp('reflectivity')}
                clearcoat={presenceMatProp('clearcoat')}
                clearcoatRoughness={presenceMatProp('clearcoatRoughness')}
                {...ballMaterialSpring}
                {...opacitySpring}
              />
            </mesh>
          </mesh>
        </a.mesh>
      </a.mesh>
    </group>
  )

  return (
    <>
      <group dispose={null} {...props} ref={mergeRefs([shellPos, ref])} visible={shellVisible}>
        <Interactive
          onSelectStart={() => onSelect(true)}
          onSelectEnd={() => onSelect(false)}
          onHover={onHover}
          onBlur={onBlur}
        >
          {/* Hover target */}
          <group>
            <Sphere args={[0.11, 8, 8]} visible={false} />
          </group>
        </Interactive>

        {/* Share one material instance for all shell pieces */}
        <a.meshStandardMaterial
          ref={setShellMaterial}
          color={getShellColor()}
          distort={0}
          roughness={shellMatProp('roughness')}
          metalness={shellMatProp('metalness')}
          // clearcoat={shellMatProp('clearcoat')}
          // clearcoatRoughness={shellMatProp('clearcoatRoughness')}
          normalMap={shellMatProp('normalMap') ? normalMap : null}
          normalScale={[shellMatProp('normalScale'), shellMatProp('normalScale')]}
          wireframe={shellWireframe}
          transparent
          {...shellMaterialSpring}
          {...opacitySpring}
        />

        <a.mesh {...part1Spring}>
          <mesh
            material={shellMaterial}
            geometry={nodes.TopLeft.geometry}
            rotation={[Math.PI, 0, -Math.PI / 2]}
            scale={[0.117, 0.117, 0.117]}
            receiveShadow
            castShadow
          />
        </a.mesh>
        <a.mesh {...part2Spring}>
          <mesh
            material={shellMaterial}
            geometry={nodes.TopRight.geometry}
            rotation={[Math.PI, 0, 0]}
            scale={[0.117, 0.117, 0.117]}
            receiveShadow
            castShadow
          />
        </a.mesh>
        <a.mesh {...part3Spring}>
          <mesh
            material={shellMaterial}
            geometry={nodes.BottomRight.geometry}
            rotation={[0, 0, 0]}
            scale={[0.117, 0.117, 0.117]}
            receiveShadow
            castShadow
          />
        </a.mesh>

        {/* Interactive bottom slice */}
        <Interactive
          onSelectStart={() => onClose(!closed)}
          onHover={() => setHoverBottom(!closed)}
          onBlur={() => setHoverBottom(false)}
        >
          <a.mesh {...part4Spring}>
            <a.mesh
              material={shellMaterial}
              geometry={nodes.BottomLeft.geometry}
              rotation={[Math.PI, 0, Math.PI]}
              scale={[0.117, 0.117, 0.117]}
              {...bottomPartSpring}
              receiveShadow
              castShadow
            />
          </a.mesh>
        </Interactive>
        {!draggable && renderPresence()}
      </group>

      {/* Dynamic ball position - keep in global world space */}
      {draggable && createPortal(renderPresence(props), scene)}
    </>
  )
}

useGLTF.preload(src, false)
useTexture.preload(normalMapSrc)
useTexture.preload(gradientPrimarySrc)

export default forwardRef(PlutoLogo)
