/* eslint-disable no-unused-vars */
// @ts-nocheck

import React, { Suspense, useEffect, useCallback, useState, useLayoutEffect } from 'react'
import { GlobalCanvas, useScrollRig } from '@14islands/r3f-scroll-rig'
import { VRCanvas, useHitTest, Interactive } from '@react-three/xr'
import { useFrame, useThree } from '@react-three/fiber'
import { Environment, useProgress, useTexture, Box } from '@react-three/drei'
import { Perf } from 'r3f-perf'
import { Leva } from 'leva'
import { DefaultLoadingManager } from 'three'

import useUIContext, { UISideEffects } from 'context/ui'
import Header from 'components/ui/Header'
import Noise from 'components/ui/Noise'
import XRDetector from 'components/xr/XRDetector'
import XRScene from 'components/xr/XRScene'
import XRStats from 'components/xr/XRStats'
import hdriSrc from 'assets/xr/envmap/hdri/gradient-light-1k.hdr'
useTexture.preload(hdriSrc)

import 'wipe.css'
import 'styles/styles.scss'

const CAMERA_XR_OFFSET_Z = 0.75 // spawn distance from the table in VR

// eslint-disable-next-line react/prop-types
function HtmlPage({ element }) {
  const inXR = useUIContext(s => s.inXR)
  if (inXR) return null
  return (
    <>
      {/* HTML Page */}
      <Noise />
      <Header />
      {element}
    </>
  )
}

function FixAlphaClearColor() {
  const inXR = useUIContext(s => s.inXR)
  const { requestRender } = useScrollRig()
  useFrame(() => {
    if (!inXR) {
      // always run global render to clear alpha on entire screen for Oculus browser :shrug:
      requestRender()
    }
  }, 0)
  return null
}

function ARSceneHitTest() {
  const [target, setTarget] = useState()
  const [scene, setScene] = useState()
  const [isPlaced, setPlaced] = useState(false)
  const { requestRender } = useScrollRig()
  useFrame(() => {
    requestRender()
  })

  useHitTest(hit => {
    if (!isPlaced) {
      hit.decompose(scene.position, scene.rotation, scene.scale)
    }
  })

  return (
    <>
      <Interactive
        onSelect={() => {
          setPlaced(true)
        }}
      >
        <XRScene isAR ref={setScene} showObjects={isPlaced} />
      </Interactive>
    </>
  )
}

function Loader() {
  const { progress, loaded, total, item, errors } = useProgress()
  const isLoaded = useUIContext(s => s.isLoaded)
  const setIsLoaded = useUIContext(s => s.setIsLoaded)
  const setLoadProgress = useUIContext(s => s.setLoadProgress)

  const onLoad = useCallback(() => setIsLoaded(true), [setIsLoaded])

  // default loading
  useEffect(() => {
    // console.log('progress', progress, 'loaded', loaded, 'total', total, 'errors', errors, 'item', item)
    if (isLoaded) return
    setLoadProgress(Math.round(progress))
    progress >= 100 && !isLoaded && setTimeout(onLoad, 2000)
  }, [progress, setLoadProgress, isLoaded, onLoad, loaded, total])

  // fallback loading
  useEffect(() => {
    const fallbackLoader = setTimeout(() => {
      if (isLoaded) return
      setIsLoaded(true)
    }, 5000)
    return () => clearTimeout(fallbackLoader)
  }, [isLoaded, setIsLoaded])

  return null
}

/* Mounted while global canvas is suspended */
const PreloadGlobalSceneOnUnMount = () => {
  const { preloadScene } = useScrollRig()
  const { scene, camera } = useThree()
  useEffect(() => {
    // preload global scene (ScrollScene) when we stop suspending
    return () => {
      preloadScene(scene, camera)
    }
  }, [preloadScene, scene, camera])
  return null
}

function WebGL() {
  const debugXR = window.location.href.includes('debugXR')
  const xrStats = window.location.href.includes('xrStats')
  const perf = window.location.href.includes('perf')
  const inXR = useUIContext(s => s.inXR)
  const inAR = useUIContext(s => s.inAR)
  const inVR = useUIContext(s => s.inVR)
  return (
    <>
      <GlobalCanvas
        as={VRCanvas}
        //vr={inXR} // We want to toggle this off from the start so the renderloop can be paused when not hovering 3D objects. Not supported yet: https://github.com/pmndrs/react-three-fiber/pull/1466
        shadows
        onCreated={({ gl }) => {
          // Store gl context for XRButton
          useUIContext.setState({ gl })
        }}
        noEvents={false}
        config={{ disableAutoClear: false /* Need auto clear to render alpha clear color in Oculus Browser */ }}
        fallback={<PreloadGlobalSceneOnUnMount />}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          height: '100vh', // use 100vh to avoid resize on iOS when url bar goes away
          zIndex: 1, // to sit on top of the page-transition-links styles
          pointerEvents: inXR ? 'auto' : 'none',
          transform: 'translateZ(0)',
        }}
      >
        <Environment files={hdriSrc} />
        <FixAlphaClearColor />
        <XRDetector
          debug={debugXR}
          onPresenting={({ player, isPresenting }) => {
            useUIContext.getState().setInXR(isPresenting)
            if (isPresenting) {
              // move player instead of content for easier positioning in XR
              if (useUIContext.getState().inVR) {
                player.position.z = CAMERA_XR_OFFSET_Z
              }
            }
          }}
        >
          <Suspense fallback={null}>
            {inVR && <XRScene debug={debugXR} />}
            {inAR && <ARSceneHitTest />}
            {xrStats && <XRStats />}
          </Suspense>
        </XRDetector>
        {perf && <Perf />}
      </GlobalCanvas>
      <Leva hidden={!debugXR} />
    </>
  )
}

// eslint-disable-next-line react/prop-types
export const wrapPageElement = ({ element }) => {
  return (
    <>
      <UISideEffects />
      <HtmlPage element={element} />
      <Loader />
      <WebGL />
    </>
  )
}
