/*
Auto-generated by: https://github.com/pmndrs/gltfjsx

NOTE: Updated with important logic.
*/

import { Mesh, MeshStandardMaterial, Group, FrontSide } from 'three'
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import { a, useSpring } from '@react-spring/three'
import { easeExpOut } from 'd3-ease'

import srcDraco from 'assets/xr/glb/optimized/join-us.draco.glb'
import src from 'assets/xr/glb/join-us.glb'
import { shouldUseDraco } from 'lib/shouldUseDraco'
const useDraco = shouldUseDraco()

type GLTFResult = GLTF & {
  nodes: {
    joinus_1: Mesh
    Icosphere001: Mesh
  }
  materials: {
    ['BASIC WHITE.002']: MeshStandardMaterial
    ['BASIC WHITE.006']: MeshStandardMaterial
  }
}
type Props = {
  willAnimateIn?: boolean
  isAnimatingIn?: boolean
  delay?: number
}

const config = { duration: 1000, easing: easeExpOut }

export default function Model({
  willAnimateIn = false,
  isAnimatingIn = false,
  delay = 0,
  ...props
}: Props & JSX.IntrinsicElements['group']) {
  const group = useRef<Group>()
  const { nodes, materials } = useGLTF(useDraco ? srcDraco : src, useDraco) as GLTFResult

  const scaleSpring = useSpring({
    scale: willAnimateIn ? (isAnimatingIn ? 1 : 0) : 1,
    from: {
      scale: willAnimateIn ? 0 : 1,
    },
    delay,
    config,
  })

  return (
    <group ref={group} {...props} dispose={null}>
      <a.group {...scaleSpring}>
        <mesh castShadow receiveShadow geometry={nodes.Icosphere001.geometry} material={materials['BASIC WHITE.006']} />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.joinus_1.geometry}
          material={materials['BASIC WHITE.002']}
          material-side={FrontSide}
        />
      </a.group>
    </group>
  )
}

useGLTF.preload(useDraco ? srcDraco : src, useDraco)
