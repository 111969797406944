import React, { useState } from 'react'
import { MeshPhysicalMaterial, MeshPhysicalMaterialParameters, Shader } from 'three'
import { useFrame } from '@react-three/fiber'

/* eslint import/no-webpack-loader-syntax: off */
/* eslint import/no-unresolved: off */
// @ts-ignore
import noise from '!raw-loader!glslify-loader!./noise.glsl'

interface Uniform<T> {
  value: T
}

class MagicalMaterialImp extends MeshPhysicalMaterial {
  _time: Uniform<number>
  _distort: Uniform<number>
  _radius: Uniform<number>
  _frequency: Uniform<number>
  _speed: Uniform<number>

  constructor(parameters: MeshPhysicalMaterialParameters = {}) {
    super(parameters)
    this.setValues(parameters)
    this._time = { value: 0 }
    this._distort = { value: 0.4 }
    this._radius = { value: 1 }
    this._frequency = { value: 2 }
    this._speed = { value: 1 }
  }

  onBeforeCompile(shader: Shader) {
    shader.uniforms.time = this._time
    shader.uniforms.radius = this._radius
    shader.uniforms.distort = this._distort
    shader.uniforms.frequency = this._frequency

    shader.vertexShader = `
      uniform float time;
      uniform float radius;
      uniform float distort;
      uniform float frequency;
      ${noise}
      
      vec3 f(vec3 point) {
        float updateTime = time / 50.0;
        float noise = cnoise(vec3(point / (frequency * 0.05) + updateTime * 5.0));
        return vec3(point * (noise * pow(distort, 2.0) + radius));
       
      }

      ${shader.vertexShader}
    `

    shader.vertexShader = shader.vertexShader.replace(
      '#include <begin_vertex>',
      `
        vec3 transformed = f(position);
      `,
    )
  }

  get time() {
    return this._time.value
  }

  set time(v) {
    this._time.value = v
  }

  get distort() {
    return this._distort.value
  }

  set distort(v) {
    this._distort.value = v
  }

  get radius() {
    return this._radius.value
  }

  set radius(v) {
    this._radius.value = v
  }

  get frequency() {
    return this._frequency.value
  }

  set frequency(v) {
    this._frequency.value = v
  }

  get speed() {
    return this._speed.value
  }

  set speed(v) {
    this._speed.value = v
  }
}

export const MagicalMaterial = React.forwardRef(({ ...props }, ref) => {
  const [material] = useState(() => new MagicalMaterialImp())
  useFrame(() => material && (material.time += 0.01 * material.speed))
  return <primitive dispose={undefined} object={material} ref={ref} attach='material' {...props} />
})

MagicalMaterial.displayName = 'MagicalMaterial'

export default MagicalMaterial
