import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import classNames from 'classnames/bind'

import ViewportEnter from 'components/core/ViewportEnter'
import { FileWithFluidGatsbyImage } from 'types/GatsbyImage'

import { GradientBackgroundType } from './GradientBackgroundTypes'
import * as s from './GradientBackground.module.scss'

const cn = classNames.bind(s)

type QueryData = {
  gradientDesktop: FileWithFluidGatsbyImage
  gradientMobile: FileWithFluidGatsbyImage
}

const query = graphql`
  query GradientBackgroundQuery {
    gradientDesktop: file(relativePath: { eq: "images/top-gradient-desktop.png" }) {
      childImageSharp {
        fluid(maxWidth: 2880) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    gradientMobile: file(relativePath: { eq: "images/top-gradient-mobile.png" }) {
      childImageSharp {
        fluid(maxWidth: 768) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const GradientBackground = ({ zIndex = 0 }: GradientBackgroundType) => {
  const data = useStaticQuery(query) as QueryData
  const [isInView, setIsInView] = useState(false)

  return (
    <ViewportEnter onEnter={() => setIsInView(true)}>
      <div className={cn('gradient', { isInView })} style={{ zIndex }}>
        <GatsbyImage className={cn('image', 'mobile')} fluid={data.gradientMobile.childImageSharp.fluid} />
        <GatsbyImage className={cn('image', 'desktop')} fluid={data.gradientDesktop.childImageSharp.fluid} />
      </div>
    </ViewportEnter>
  )
}

export default GradientBackground
