/*
Auto-generated by: https://github.com/pmndrs/gltfjsx

NOTE: Updated with important logic.
*/

import { Mesh, Group, MeshStandardMaterial, FrontSide } from 'three'
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import { GLTF } from 'three-stdlib'
import { a, useSpring } from '@react-spring/three'
import { easeExpOut } from 'd3-ease'
import lerp from '@14islands/lerp'

import useMeshBoundsHoverTarget from 'lib/useMeshBoundsHoverTarget'
import srcDraco from 'assets/xr/glb/optimized/plutosphere-dark.draco.glb'
import src from 'assets/xr/glb/plutosphere-dark.glb'
import { shouldUseDraco } from 'lib/shouldUseDraco'
const useDraco = shouldUseDraco()

type GLTFResult = GLTF & {
  nodes: {
    PRESENCE_PLUTOSPHERE: Mesh
    PLUTOSPHERE_DARK: Mesh
  }
  materials: {
    ['Presence.Multicolor']: MeshStandardMaterial
    ['Plutosphere.Dark']: MeshStandardMaterial
  }
}

type Props = {
  makeFlat?: boolean
  spin?: boolean
  willAnimateIn?: boolean
  isAnimatingIn?: boolean
  delay?: number
}

const config = { duration: 1000, easing: easeExpOut }

export default function Model({
  makeFlat = false,
  spin = false,
  willAnimateIn = false,
  isAnimatingIn = false,
  delay = 0,
  ...props
}: Props & JSX.IntrinsicElements['group']) {
  const group = useRef<Group>()
  const mesh = useRef<Mesh>()
  const { nodes, materials } = useGLTF(useDraco ? srcDraco : src, useDraco) as GLTFResult

  useMeshBoundsHoverTarget(mesh.current)

  const scaleSpring = useSpring({
    scale: willAnimateIn ? (isAnimatingIn ? 1 : 0) : 1,
    from: {
      scale: willAnimateIn ? 0 : 1,
    },
    delay,
    config,
  })

  const matSpring = useSpring({
    envMapIntensity: makeFlat ? 0 : 1,
    color: makeFlat ? '#555' : '#' + materials['Plutosphere.Dark'].color.getHexString(),
    metalness: makeFlat ? 0 : materials['Plutosphere.Dark'].metalness,
    config,
  })

  useFrame((_, delta) => {
    if (mesh.current) {
      if (!spin) {
        mesh.current.rotation.x = lerp(
          mesh.current.rotation.x,
          Math.round(mesh.current.rotation.x / (Math.PI * 2)) * Math.PI * 2,
          0.1,
          delta,
        )
        mesh.current.rotation.z = lerp(
          mesh.current.rotation.z,
          Math.round(mesh.current.rotation.z / (Math.PI * 2)) * Math.PI * 2,
          0.1,
          delta,
        )
      } else {
        mesh.current.rotation.x -= delta * 0.33
        mesh.current.rotation.z += delta * 0.33
      }
    }
  })

  return (
    <group ref={group} {...props} dispose={null}>
      <a.group {...scaleSpring}>
        <mesh ref={mesh} castShadow receiveShadow geometry={nodes.PLUTOSPHERE_DARK.geometry}>
          <a.primitive
            object={materials['Plutosphere.Dark'].clone()}
            attach='material'
            {...matSpring}
            side={FrontSide}
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.PRESENCE_PLUTOSPHERE.geometry}
          material={materials['Presence.Multicolor']}
          material-envMapIntensity={makeFlat ? 0 : 1}
          material-side={FrontSide}
          renderOrder={4}
        />
      </a.group>
    </group>
  )
}

useGLTF.preload(useDraco ? srcDraco : src, useDraco)
