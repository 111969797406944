import React, { useEffect } from 'react'
import { useXR } from '@react-three/xr'
import { useThree } from '@react-three/fiber'
import { OrbitControls } from '@react-three/drei'
import { Object3D } from 'three'

import useUIContext from 'context/ui'

type OnPresentingProps = {
  isPresenting: boolean
  player: Object3D
}

type XRDetectorProps = {
  children: unknown
  onPresenting: ({ isPresenting, player }: OnPresentingProps) => void
  debug: boolean
}

export default function XRDetector({ children, onPresenting, debug }: XRDetectorProps) {
  const { camera } = useThree()
  const { isPresenting, player } = useXR()

  useEffect(() => {
    if (debug) useUIContext.setState({ inVR: true })
    onPresenting({ isPresenting: debug || isPresenting, player })
    if (debug) {
      // @ts-ignore
      camera.fov = 90
      camera.position.z = 0
      camera.position.y = 1.4
      camera.updateProjectionMatrix()
    }
  }, [debug, isPresenting, onPresenting, player, camera])

  if (debug || isPresenting) {
    return (
      <>
        {children || null}
        {debug && <OrbitControls camera={camera} target={[0, 1, -0.75]} />}
      </>
    )
  }
  return null
}
