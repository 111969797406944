// extracted by mini-css-extract-plugin
export var colorBackground = "#151515";
export var colorForeground = "#fff";
export var colorGradient1Start = "#f969fc";
export var colorGradient1Mid = "#bc01f0";
export var colorGradient1End = "#0c01de";
export var colorCaption = "#e5e4e5";
export var colorGlitchGreen = "#55ff7a";
export var colorGlitchPurple = "#bc01f0";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1000px";
export var breakpointLarge = "1400px";
export var gradient = "GradientBackground-module--gradient--rSvUt";
export var isInView = "GradientBackground-module--isInView--42hyf";
export var image = "GradientBackground-module--image--M1-f-";
export var mobile = "GradientBackground-module--mobile--1bBkn";
export var desktop = "GradientBackground-module--desktop--q03hd";