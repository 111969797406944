import React, { useRef } from 'react'
import { useThree } from '@react-three/fiber'
import { DoubleSide, RingBufferGeometry } from 'three'
import { useSpring, a } from '@react-spring/three'
import { easeQuadOut } from 'd3-ease'

import { CirclelineProps } from './Props'

const Circleline = ({ size, thickness, color, shouldAnimate, duration, delay, thetaStart }: CirclelineProps) => {
  const mesh = useRef<THREE.Mesh>(null)
  const {
    viewport: { factor },
  } = useThree()

  useSpring({
    theta: shouldAnimate ? 2 : 0,
    delay: delay * 100,
    config: {
      duration: duration * 1000,
      easing: easeQuadOut,
    },
    onChange: spring => {
      if (!mesh || !mesh.current) return
      mesh.current.geometry.dispose() // dispose old geometry
      mesh.current.geometry = new RingBufferGeometry(
        size,
        size + thickness / factor,
        256,
        0,
        thetaStart,
        spring.value.theta * Math.PI,
      ) // create new geometry with new theta
    },
  })

  return (
    <mesh ref={mesh} position={[0, 0.01, 0]} rotation={[Math.PI * 0.5, 0, 0]}>
      <a.meshBasicMaterial attach='material' side={DoubleSide} color={color} depthWrite={false} />
      <ringBufferGeometry attach='geometry' args={[size, size + thickness / factor, 256, 0, 0, 0]} />
    </mesh>
  )
}

export default Circleline
